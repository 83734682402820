<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t('cip.dc.Token.title.Addname')"
                 @head-save="headSave('save')"
                 @head-save-back="headSave('back')"
                 @head-cancel="headCancel"
    ></head-layout>
    <div class="newZtFormBox" style="background-color: white;margin-top: 10px;">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px">
        <!-- <form-layout ref="formLayout" :dataForm="form" :group="group"></form-layout> -->
        <el-collapse v-model="activeName">
          <el-collapse-item :title="$t('cip.dc.Token.field.RequestHeader')" name="1">
            <template slot="title">
              <i class="avue-group__title avue-group__header" style="color: #999999 !important; font-style: normal;padding: 0 10px;"><i class="el-icon-document" style="font-size: 20px;padding: 0 10px;"></i> {{ $t("cip.dc.Token.field.baseInfo") }}
              </i>
            </template>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.Token.field.Interfacecode')" prop="infCode">
                  <el-input v-model="form.infCode" autocomplete="off" class='new-form-input' disabled></el-input>
                </el-form-item>

              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.Token.field.Fieldname')" prop="tokenField">
                  <el-input v-model="form.tokenField" :placeholder="$t('cip.dc.Token.field.Fieldname')" autocomplete="off" class='new-form-input'></el-input>
                </el-form-item>

              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.Token.field.Interfaceaddress')" prop="tokenUrl">
                  <el-input v-model="form.tokenUrl" :placeholder="$t('cip.dc.Token.field.Interfaceaddress')" autocomplete="off" class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.Token.field.RequestMethod')" prop="RequestMethod">
                  <el-select v-model="form.RequestMethod" :placeholder="$t('cip.dc.Token.field.RequestMethod')" class='new-form-input'>
                    <el-option :value="1" label="get"></el-option>
                    <el-option :value="2" label="post"></el-option>
                  </el-select>
                </el-form-item>

              </el-col>




              <!-- </el-col> -->
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.Token.field.Splicinginformation')">
                  <el-input v-model="form.tokenPrefix" :placeholder="$t('cip.dc.Token.field.Splicinginformation')" autocomplete="off" class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.Token.field.Requestinformation')">
                  <el-input v-model="form.config" :placeholder="$t('cip.dc.Token.field.Requestinformation')" autocomplete="off" class='new-form-input' disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row>

              <el-col :span="8"></el-col>
              <el-col :span="8"></el-col>
            </el-row> -->
          </el-collapse-item>



          <el-collapse-item :title="$t('cip.dc.Token.field.RequestHeader')" name="2">
            <template slot="title">
              <i class="avue-group__title avue-group__header" style="color: #999999 !important; font-style: normal;padding: 0 10px;"><i class="el-icon-document" style="font-size: 20px;padding: 0 10px;"></i> {{ $t('cip.dc.Token.field.RequestHeader') }}
              </i>
            </template>
            <el-row>
              <div style="margin-bottom: 20px;padding-left: 30px">
                <el-button icon="el-icon-circle-plus-outline" size='mini' @click="headerListAddFn">{{ $t('cip.dc.Token.field.Addrequestheader') }}</el-button>
              </div>
              <!-- <el-col v-for="(item,index) in form.headerList" style="margin-bottom: 20px!important;">
                key: <el-input placeholder="key" class = 'new-form-input' v-model="item.key"  autocomplete="off"></el-input>
                value:<el-input placeholder="value" class = 'new-form-input' v-model="item.value"  autocomplete="off"></el-input>
                <el-button size = 'mini' type="danger" icon="el-icon-delete" circle @click="headerListDelFn(index)"></el-button>
              </el-col> -->


              <el-table
                :data="form.headerList"
                border
                style="width: 100%">
                <el-table-column
                  label="key:"
                  prop="key"
                >
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.key" autocomplete="off" class='new-form-input' placeholder='key'></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  label="value:"
                  prop="value"
                >
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.value" autocomplete="off" class='new-form-input' placeholder='key'></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="this.$t('cip.cmn.btn.editBtn')"
                  prop="address">
                  <template slot-scope="scope">
                    <el-button circle icon="el-icon-delete" type="danger" @click="headerListDelFn(scope.row)"></el-button>
                  </template>
                </el-table-column>
              </el-table>

            </el-row>
          </el-collapse-item>
          <el-collapse-item :title="$t('cip.dc.Token.field.RequestMethod')" name="3">
            <template slot="title">
              <i class="avue-group__title avue-group__header" style="color: #999999 !important; font-style: normal;padding: 0 10px;"><i class="el-icon-document" style="font-size: 20px;padding: 0 10px;"></i> {{ $t('cip.dc.Token.field.RequestMethod') }}
              </i>
            </template>
            <el-row>
              <div style="margin-bottom: 20px;padding-left: 30px;display: flex;align-items: center">
                <el-button icon="el-icon-circle-plus-outline" size='mini' @click="headerconfigListAddFn">{{ $t('cip.dc.Token.field.Addrequestheader') }}</el-button>
              </div>
              <!-- <div >
                <el-row v-for="(item,index) in form.configList" >
                  <el-col :span="8">
                    key: <el-input  placeholder="key" style = 'width:250px' class = 'new-form-input' v-model="item.key"  autocomplete="off"></el-input>
                  </el-col>
                  <el-col :span="8">
                    value:<el-input  placeholder="value" style = 'width:250px' class = 'new-form-input' v-model="item.value"  autocomplete="off"></el-input>
                  </el-col>
                </el-row>
              </div> -->
              <el-table
                :data="form.configList"
                border
                style="width: 100%">
                <el-table-column
                  label="key:"
                  prop="key"
                >
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.key" autocomplete="off" class='new-form-input' placeholder='key'></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  label="value:"
                  prop="value"
                >
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.value" autocomplete="off" class='new-form-input' placeholder='key'></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="this.$t('cip.cmn.btn.editBtn')"
                  prop="address">
                  <template slot-scope="scope">
                    <el-button circle icon="el-icon-delete" type="danger" @click="configListDelFn(scope.row)"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
  </div>
</template>

<script>
import {datSoSel, tokenDetail, tokenInitcode, tokentUp} from "@/api/dataAcquisition";
import FormLayout from "@/views/components/layout/form-layout";
import {listAll2} from "@/api/dataAcquisition/DataServices";

export default {
  name: "dataCoonfigAdd",
  computed: {
    group() {
      return [
        {
          label: this.$t("cip.plat.sys.user.title.baseInfoTitle"),
          prop: 'baseInfo',
          icon: 'el-icon-document',
          labelWidth: 120,
          column: [
            {
              label: this.$t("cip.dc.Token.field.InterfaceCode"),
              prop: "infCode",
              disabled: true,
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("zljkcj.label.InterfaceCode"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.Token.field.Fieldname"),
              prop: "tokenField",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.Token.field.Fieldname"),
                trigger: "submit"
              }]
            },

            {
              label: this.$t("cip.dc.Token.field.Interfaceaddress"),
              prop: "tokenUrl",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.Token.field.Interfaceaddress"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.Token.field.Requestmethod"),
              prop: "RequestMethod",
              // dicData: [
              //   {
              //     label: "get",
              //     value: "1"
              //   },
              //   {
              //     label: "post",
              //     value: "2"
              //   }
              // ],
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=getOrPost",
              type: "select",
              slot: true,
              props: {
                label: "label",
                value: "value"
              },
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("zljkcj.label.RequestMethod"),
                trigger: "click"
              }]
            },


            {
              label: this.$t("cip.dc.Token.field.Datasourceid"),
              prop: "sourceId",
              dicData: [],
              type: "select",
              slot: true,
              props: {
                label: "name",
                value: "id"
              },
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("zljkcj.label.RequestMethod"),
                trigger: "click"
              }]
            },


            {
              label: this.$t("cip.dc.Token.field.Splicinginformation"),
              prop: "tokenPrefix",

            },

            {
              label: this.$t("cip.dc.Token.field.Requestinformation"),
              prop: "config",
              disabled: true,

            },


          ]
        }
      ]
    },
  },
  data() {
    return {
      idList: [],
      alist: [],
      activeName: '1',
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        infCode: [
          {required: true, message: this.$t('cip.dc.Token.field.Interfacecode'), trigger: 'change'}
        ],
        tokenField: [
          {required: true, message: this.$t('cip.dc.Token.field.Fieldname'), trigger: 'change'}
        ],
        tokenUrl: [
          {required: true, message: this.$t('cip.dc.Token.field.Interfaceaddress'), trigger: 'change'}
        ],
        RequestMethod: [
          {required: true, message: this.$t('cip.dc.Token.field.RequestMethod'), trigger: 'change'}
        ],
        Tokensourceid: [
          {required: true, message: this.$t('cip.dc.Token.field.Tokensourceid'), trigger: 'change'}
        ],
      },
      form: {
        infCode: '',
        tokenField: '',
        tokenUrl: '',
        RequestMethod: '',
        // sourceId:'',
        // tokenId:'',
        "headerList": [],
        "configList": [],
      },
      formLabelWidth: '120px',
      selDat: []

    }
  },
  components: {
    FormLayout
  },
  created() {
    this.datSoSelFn()
    this.getTokenDetail()
    // this.getlistAll()
    if (this.$route.query.type == 'add') {
      this.getAutoCodeFn()
    }

  },
  mounted() {

  },
  methods: {

    async getTokenDetail() {
      if (this.$route.query.type !== 'edit') return
      const res = await tokenDetail(this.$route.query.id)
      this.form = res.data.data
      this.form.tokenUrl = decodeURIComponent(this.form.tokenUrl)
    },

    getlistAll() {
      // listAll({}).then(res=>{

      //   this.idList = res.data.data
      //   this.group[0].column.forEach(element => {

      //     if(element.prop == 'sourceId'){
      //       element.dicData = res.data.data
      //     }
      //   });
      // })
      listAll2({}).then(res => {

        this.idList = res.data.data
        // this.group[0].column.forEach(element => {

        //   if(element.prop == 'sourceId'){
        //     element.dicData = res.data.data
        //   }
        // });
      })

    },
    headSave(type) {
      // this.$refs.formLayout.$refs.form.validate((valid) => {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            console.log(this.form.tokenUrl, encodeURIComponent(this.form.tokenUrl));
            this.form.tokenUrl = encodeURIComponent(this.form.tokenUrl)

            tokentUp(this.form).then(() => {
              this.$message.success(this.$t('cip.dc.Token.msg'));
              this.form.tokenUrl = decodeURIComponent(this.form.tokenUrl)
              if (type == 'back') {
                this.headCancel()
              }
            })
          } else {
            console.log(this.form.tokenUrl, encodeURIComponent(this.form.tokenUrl));
            this.form.tokenUrl = encodeURIComponent(this.form.tokenUrl)
            tokentUp(this.form).then(() => {
              this.$message.success(this.$t('cip.dc.Token.msg'));
              this.form.tokenUrl = decodeURIComponent(this.form.tokenUrl)
              if (type == 'back') {
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headerListAddFn() {
      this.form.headerList.push({key: '', value: ''})
    },
    headerListDelFn(e) {
      let index = ""
      this.form.headerList.forEach((element, idx) => {
        if (element == e) {
          index = idx
        }
      });
      this.form.headerList.splice(index, 1)
    },
    configListDelFn(e) {
      let index = ""
      this.form.configList.forEach((element, idx) => {
        if (element == e) {
          index = idx
        }
      });
      this.form.configList.splice(index, 1)
    },
    headerconfigListAddFn() {
      this.form.configList.push({key: '', value: '', type: null})
    },
    headerconfigListDelFn(index) {
      this.form.configList.splice(index, 1)
    },
    datSoSelFn() {
      datSoSel().then((res) => {
        this.selDat = res.data.data;
      })
    },
    getAutoCodeFn() {

      tokenInitcode().then((res) => {
        this.form.infCode = res.data.data;
        this.headerListAddFn()
      })


    },


  }
}
</script>

<style scoped>
/* /deep/.el-input__inner{
  width: 200px;
 } */
</style>
