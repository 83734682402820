var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.dc.Token.title.Addname"),
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        {
          staticClass: "newZtFormBox",
          staticStyle: { "background-color": "white", "margin-top": "10px" },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    {
                      attrs: {
                        title: _vm.$t("cip.dc.Token.field.RequestHeader"),
                        name: "1",
                      },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("cip.dc.Token.field.baseInfo")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.Token.field.Interfacecode"
                                    ),
                                    prop: "infCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      autocomplete: "off",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.form.infCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "infCode", $$v)
                                      },
                                      expression: "form.infCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.Token.field.Fieldname"
                                    ),
                                    prop: "tokenField",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.Token.field.Fieldname"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.tokenField,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "tokenField", $$v)
                                      },
                                      expression: "form.tokenField",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.Token.field.Interfaceaddress"
                                    ),
                                    prop: "tokenUrl",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.Token.field.Interfaceaddress"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.tokenUrl,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "tokenUrl", $$v)
                                      },
                                      expression: "form.tokenUrl",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.Token.field.RequestMethod"
                                    ),
                                    prop: "RequestMethod",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "new-form-input",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "cip.dc.Token.field.RequestMethod"
                                        ),
                                      },
                                      model: {
                                        value: _vm.form.RequestMethod,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "RequestMethod",
                                            $$v
                                          )
                                        },
                                        expression: "form.RequestMethod",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: 1, label: "get" },
                                      }),
                                      _c("el-option", {
                                        attrs: { value: 2, label: "post" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.Token.field.Splicinginformation"
                                    ),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.Token.field.Splicinginformation"
                                      ),
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.form.tokenPrefix,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "tokenPrefix", $$v)
                                      },
                                      expression: "form.tokenPrefix",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "cip.dc.Token.field.Requestinformation"
                                    ),
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "new-form-input",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "cip.dc.Token.field.Requestinformation"
                                      ),
                                      autocomplete: "off",
                                      disabled: "",
                                    },
                                    model: {
                                      value: _vm.form.config,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "config", $$v)
                                      },
                                      expression: "form.config",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      attrs: {
                        title: _vm.$t("cip.dc.Token.field.RequestHeader"),
                        name: "2",
                      },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("cip.dc.Token.field.RequestHeader")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "20px",
                                "padding-left": "30px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "mini",
                                  },
                                  on: { click: _vm.headerListAddFn },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "cip.dc.Token.field.Addrequestheader"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.form.headerList, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "key:", prop: "key" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "new-form-input",
                                          attrs: {
                                            autocomplete: "off",
                                            placeholder: "key",
                                          },
                                          model: {
                                            value: scope.row.key,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "key", $$v)
                                            },
                                            expression: "scope.row.key",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "value:", prop: "value" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "new-form-input",
                                          attrs: {
                                            autocomplete: "off",
                                            placeholder: "key",
                                          },
                                          model: {
                                            value: scope.row.value,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "value", $$v)
                                            },
                                            expression: "scope.row.value",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: this.$t("cip.cmn.btn.editBtn"),
                                  prop: "address",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-button", {
                                          attrs: {
                                            circle: "",
                                            icon: "el-icon-delete",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.headerListDelFn(
                                                scope.row
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-collapse-item",
                    {
                      attrs: {
                        title: _vm.$t("cip.dc.Token.field.RequestMethod"),
                        name: "3",
                      },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "i",
                          {
                            staticClass: "avue-group__title avue-group__header",
                            staticStyle: {
                              color: "#999999 !important",
                              "font-style": "normal",
                              padding: "0 10px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document",
                              staticStyle: {
                                "font-size": "20px",
                                padding: "0 10px",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("cip.dc.Token.field.RequestMethod")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-row",
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "20px",
                                "padding-left": "30px",
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-circle-plus-outline",
                                    size: "mini",
                                  },
                                  on: { click: _vm.headerconfigListAddFn },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "cip.dc.Token.field.Addrequestheader"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.form.configList, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "key:", prop: "key" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "new-form-input",
                                          attrs: {
                                            autocomplete: "off",
                                            placeholder: "key",
                                          },
                                          model: {
                                            value: scope.row.key,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "key", $$v)
                                            },
                                            expression: "scope.row.key",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "value:", prop: "value" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          staticClass: "new-form-input",
                                          attrs: {
                                            autocomplete: "off",
                                            placeholder: "key",
                                          },
                                          model: {
                                            value: scope.row.value,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "value", $$v)
                                            },
                                            expression: "scope.row.value",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: this.$t("cip.cmn.btn.editBtn"),
                                  prop: "address",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-button", {
                                          attrs: {
                                            circle: "",
                                            icon: "el-icon-delete",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.configListDelFn(
                                                scope.row
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }